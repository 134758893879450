import cloneDeep from 'lodash/cloneDeep';
import { GenericStrikeZone } from '@/enums/Generic';
import { Layouts, Images, SeriesFields } from '@/components/ui/charts/plotly/PlotlyHelpers';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';

export default {
  name: 'BattedBallQualityChart',

  extends: ChartMiddlewareVue,

  data() {
    return {
      adjustRanges: false,
      chartType: ChartTypeEnum.BATTED_BALL_QUALITY.type,
      strikeZoneModel: new GenericStrikeZone(),
      plotlyLayoutExtended: cloneDeep(Layouts.scatterpolarChart),
    };
  },

  methods: {
    seriesConverter(mapped) {
      return this.convertSeries(mapped, this.unitSystem.system, null, null, [SeriesFields.R]);
    },

    customMapping(converted) {
      // let symbol = this.unitSymbolX(this.unitSystem.system);
      this.plotlyData = converted.map((trace) => {
        return this.mapDefaultTraceSettings(trace, {
          type: 'scatterpolar',
          mode: 'markers',
          hoverinfo: 'none',
          hovertemplate: '',
        });
      });

      const maxTickValue = this.plotlyData.reduce((acc, curr) => {
        if (curr.r?.length) {
          const max = Math.max(...curr.r);
          if (max > acc) {
            acc = max;
          }
        }

        return acc;
      }, this.isMetric ? 200 : 120);

      this.plotlyLayoutExtended.polar.radialaxis.dtick = 20;
      this.plotlyLayoutExtended.polar.radialaxis.tick0 = 20;
      this.plotlyLayoutExtended.polar.radialaxis.range = [0, maxTickValue];

      if (this.isMetric) {
        this.plotlyLayoutExtended.polar.radialaxis.ticksuffix = ' km/h';
      } else {
        this.plotlyLayoutExtended.polar.radialaxis.ticksuffix = ' mph';
      }

      this.plotlyLayoutExtended.title.text = this.$vuetify.lang.t('$vuetify.reports.battedBallQualityChart');
      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);

      this.plotlyLayoutExtended.images = [Images.battedBallQualityPlayer];
    },
  },
};
